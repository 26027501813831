import type { Locale } from "../schema/Locale.js";

const dateMonthYearFormat: Partial<Record<Locale, Intl.DateTimeFormat>> = {};
function createFormat(locale: Locale): Intl.DateTimeFormat {
	dateMonthYearFormat[locale] ??= new Intl.DateTimeFormat(locale, {
		day: "numeric",
		month: "numeric",
		year: "numeric",
		timeZone: "Europe/Prague",
	});
	return dateMonthYearFormat[locale];
}

export function formatDateTime(date: number | Date | string, locale: Locale): string {
	return createFormat(locale).format(typeof date === "string" ? new Date(date) : date);
}
